<template>
    <BT-Blade-Items
        addBladeName="courier-order"
        bladeName="courier-orders"
        :bladesData="bladesData"
        canDelete
        :dependantBlades="[{ bladeName: 'courier-order' }]"
        :getParams="item => { return { includeDetails: false, customerIDs: item.data == null ? null : item.data.customerID } }"
        :headers="[
            { text: 'CO#', value: 'courierOrderNumber', subtitle: 4, prefix: 'CO# ' },
            { text: 'Due Date', value: 'dueDate', textFilter: 'toDayMonth' },
            { text: 'Courier', value: 'seller.companyName', subtitle: 1, prefix: 'Client: ' },
            { text: 'Departure From', value: 'departureLocation', display: true, subtitle: 3, prefix: 'From: ' },
            { text: 'Destination', value: 'destinationLocation', display: true, subtitle: 2, prefix: 'To: ' },
            { text: 'Status', value: 'id', display: true, subtitle: 5, prefix: 'Status: ' }]"
        minimizeOnSelect
        :onCanDelete="item => { return item.isApproved !== true || item.seller.isManagedByAnotherAccount }"
        navigation="courier-orders"
        :refreshToggle="refreshToggle"
        title="Courier Orders"
        useServerPagination>
        <template v-slot:settings>
            <BT-Entity
                v-if="$canView('courier-settings')"
                ignoreID
                inline
                navigation="courier-settings"
                single>
                <template v-slot="{ item, save }">
                    <v-divider />
                    <v-subheader>Settings</v-subheader>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Consignment Path Planning</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <v-btn small icon :to="{ name: 'template-optimization-hub' }" title="Manage Automatic Consignment Path Planning">
                                    <v-icon small>mdi-routes</v-icon>
                                </v-btn>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.routePlanningGuide)"
                                    v-model="item.routePlanningTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon
                                    v-model="item.routePlanningGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-subtitle>Consignment Path Moving</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-row dense>
                                <BT-Toggle-Trigger-Icon
                                    :disabled="!isGlobalSetting(item.routeMovementGuide)"
                                    v-model="item.routeMovementTrigger"
                                    @change="save" />
                                <BT-Toggle-Guide-Icon
                                    v-model="item.routeMovementGuide"
                                    @change="save" />
                            </v-row>
                        </v-list-item-action>
                    </v-list-item>
                </template>
            </BT-Entity>
        </template>
        <template slot="departureLocation" slot-scope="{ item }">
            {{ item.departureLocation || item.requestedDepartureLocation | toFamiliarLocationLine }}
        </template>
        <template slot="destinationLocation" slot-scope="{ item }">
            {{ item.isPickup ? '*Pickup' : item.destinationLocation || item.requestedDestinationLocation | toFamiliarLocationLine }}
        </template>
        <template slot="id" slot-scope="{ item }">
            <span v-if="item.fulfilledOn != null">Fulfilled</span>
            <span v-else-if="item.isBilled">Billed</span>
            <span v-else-if="item.isPlanned">Planned</span>
            <span v-else-if="item.isApproved === true">Approved</span>
            <span v-else-if="item.isApproved === false">Rejected</span>
            <span v-else-if="item.isApproved == null">Unresponded</span>
        </template>
        <template v-slot:itemActions="{ item, items }">

            <BT-Single-Action
                v-if="!item.isPlanned && item.isApproved && item.fulfilledOn == null && item.sellerID == item.buyerID"
                @click="planDirectRoute(item)"
                :loading="item.loadingCount > 0"
                :icon="item.isPickup ? 'mdi-pickaxe' : 'mdi-map-marker-distance'"
                :title="item.isPickup ? 'Pickup' : 'Move Directly'" />

            <BT-Dialog
                v-if="!item.isPickup && !item.isPlanned && item.isApproved && item.fulfilledOn == null && item.sellerID == item.buyerID"
                icon="mdi-map-marker-question-outline"
                @ok="res => { createRoute(res, item) }"
                :getOnOpenAsync="getRouteSuggestionsAsync"
                :item="item"
                label="Route Suggestions"
                small
                title="Route Suggestion"
                width="550">
                <template v-slot="{ item }">
                    <v-list v-if="isLengthyArray(item.routeMovements)">
                        <v-list-item key="-1">
                            <v-list-item-content>
                                <v-list-item-title>From</v-list-item-title>
                                <v-list-item-subtitle>
                                    <BT-Entity 
                                        :itemValue="item.routeMovements[0].departureLocationID"
                                        navigation="public-locations" 
                                        single
                                        textFilter="toFamiliarLocationLine"
                                        useLocalCache />
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>{{ item.routeMovements[0].dueDepartureOn | toDayMonth }}</v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item v-for="(route, index) in item.routeMovements" :key="index">
                            <v-list-item-content>
                                <v-list-item-title>To</v-list-item-title>
                                <v-list-item-subtitle>
                                    <BT-Entity 
                                        :itemValue="route.destinationLocationID"
                                        navigation="public-locations" 
                                        single
                                        textFilter="toFamiliarLocationLine"
                                        useLocalCache />
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-list-item-action-text>{{ route.dueArrivalOn | toDayMonth }}</v-list-item-action-text>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <div v-else>No Suggestions Found</div>
                </template>
            </BT-Dialog>

            <BT-Single-Action
                v-if="item.isApproved == null && item.sellerID == item.buyerID"
                :loading="item.loadingCount > 0"
                @click="approveCourierOrder(item)"
                title="Approve" />

            <BT-Single-Action
                v-if="item.sellerID == item.buyerID && (item.isApproved == null || (item.isApproved === true && item.fulfilledOn == null))"
                :loading="item.loadingCount > 0"
                @click="rejectCourierOrder(item)"
                icon="mdi-close"
                class="error--text"
                title="Reject" />

            <BT-Single-Action
                v-if="item.sellerID == item.buyerID && item.fulfilledOn == null && item.isApproved == true"
                :loading="item.loadingCount > 0"
                icon="mdi-receipt"
                title="Fulfill Courier Order"
                @click="fulfillCourierOrder(item, items)" />

            <BT-Single-Action
                v-if="item.sellerID == item.buyerID && item.fulfilledOn != null && item.isApproved == true"
                :loading="item.loadingCount > 0"
                icon="mdi-receipt"
                title="Invoice Courier Order"
                @click="invoiceCourierOrder(item, items)" />

        </template>
    </BT-Blade-Items>
</template>

<script>
import { firstBy } from 'thenby';
export default {
    name: 'Courier-Orders-Blade',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTSingleAction: () => import('~components/BT-Single-Action.vue'),
        BTToggleTriggerIcon: () => import('~components/BT-Toggle-Trigger-Icon.vue'),
        BTToggleGuideIcon: () => import('~components/BT-Toggle-Guide-Icon.vue'),
    },
    data: function() {
        return {
            bladeData: null,
            refreshToggle: false,
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
    },
    methods: {
        async approveCourierOrder(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var data = {
                    id: item.id,
                    rowVersion: item.rowVersion,
                    departureLocationID: item.departureLocationID != null ? item.departureLocationID : item.requestedDepartureLocationID,
                    destinationLocationID: item.destinationLocationID != null ? item.destinationLocationID : item.requestedDestinationLocationID,
                    isApproved: true
                };

                var res = await this.$BlitzIt.store.patch('client-orders', data);
                
                item.departureLocationID = res.departureLocationID;
                item.departureLocation = item.departureLocation || item.requestedDepartureLocation;
                item.destinationLocationID = res.destinationLocationID;
                item.destinationLocation = item.destinationLocation || item.requestedDestinationLocation;
                item.courierOrderNumber = res.courierOrderNumber;
                item.isApproved = res.isApproved;
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        async createRoute(res, item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                for (let i = 0; i < res.routeMovements.length; i++) {
                    const routeMovement = res.routeMovements[i];
                    
                    var movement = {
                        departureLocationID: routeMovement.departureLocationID,
                        destinationLocationID: routeMovement.destinationLocationID,
                        dueDepartureOn: routeMovement.dueDepartureOn,
                        dueArrivalOn: routeMovement.dueArrivalOn,
                        bundleID: routeMovement.bundleID,
                        courierOrderIDs: [item.id]
                    }

                    await this.$BlitzIt.store.post('courier-movements', movement);

                    item.isPlanned = true;
                    this.refreshToggle = !this.refreshToggle;
                }
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        getParams(bladeData) {
            return { includeDetails: false, customerIDs: bladeData.data.customerID };
        },
        async getRouteSuggestionsAsync(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var res = await this.$BlitzIt.api.getById('route-suggestions', item.id, null, null);
                if (res.data.data != null) {
                    res.data.data.routeMovements.sort(firstBy(x => x.sortNumber));
                }
                return res.data.data;
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        async fulfillCourierOrder(item, items) {
            var data = {
                id: item.id,
                isBillRequest: true,
                fulfilledOn: this.$BlitzIt.auth.createUTC()
            };

            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                await this.$BlitzIt.api.patch('courier-order-fulfilling', data);
                this.$BlitzIt.store.deleteLocal('client-orders', item.id, null);
                var ind = items.findIndex(x => x.id == item.id);
                if (ind >= 0) {
                    items.splice(ind, 1);
                }
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        async invoiceCourierOrder(item, items) {
            var data = { id: item.id, isBillRequest: true }

            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                await this.$BlitzIt.api.patch('courier-order-fulfilling', data);
                this.$BlitzIt.store.deleteLocal('client-orders', item.id, null);
                var ind = items.findIndex(x => x.id == item.id);
                if (ind >= 0) {
                    items.splice(ind, 1);
                }
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        async planDirectRoute(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var movement = {
                    departureLocation: item.departureLocation || item.requestedDepartureLocation,
                    departureLocationID: item.departureLocationID || item.requestedDepartureLocationID,
                    destinationLocation: item.destinationLocation || item.requestedDestinationLocation,
                    destinationLocationID: item.destinationLocationID || item.requestedDestinationLocationID,
                    dueDepartureOn: item.dueDate,
                    dueArrivalOn: item.dueDate,
                    courierOrderIDs: [item.id],
                    isPickup: item.isPickup
                };
                
                await this.$BlitzIt.store.post('courier-movements', movement);
                
                item.isPlanned = true;
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
        async rejectCourierOrder(item) {
            try {
                item.loadingCount += 1;
                this.$forceUpdate();

                var data = {
                    id: item.id,
                    rowVersion: item.rowVersion,
                    isApproved: false
                };

                var res = await this.$BlitzIt.store.patch('client-orders', data);
                item.rowVersion = res.rowVersion;
                item.isApproved = false;
            }
            catch (err) {
                item.errorMsg = this.extractErrorDescription(err);
            }
            finally {
                item.loadingCount -= 1;
            }
        },
    }
}
</script>